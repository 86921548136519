import React from 'react'
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { appSS } from '../../assets/images';

function ScreenShotSlider() {
    var settings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true, // Enable auto sliding
    autoplaySpeed: 3000,
        responsive: [
            {
              breakpoint: 992, // Medium screens and below
              settings: {
                slidesToShow: 2,
              },
            },
          ],
      };
  return (
    <div className='slider_container'>
        
    <div className="container">
    <h4 className='white-heading'>Screenshot Showcase</h4>
    <Slider {...settings}>
    {
        appSS?.map((item,index)=><div key={index}>
          <a href="https://funexch.com/" target='_blank'>
   <img src={item} alt={`slide${index}`}/>
   </a>
    </div>)
    }
    
</Slider>
    </div>

</div>
  )
}

export default ScreenShotSlider