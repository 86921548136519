import React from 'react'
import { Horse, Cards, Casino, Slots, CategoryBg } from '../../assets/images'


function Categories() {
  return (
    <div class="services section" id="services">
    <div class="container">
      <div class="row">
      <div class="section-heading">
                <h2 className='green-heading'>Explore The Vast 
                  Collection Of Games
                </h2>
                <div class="line-dec"></div>
                <p>Discover a vast collection of games catering to various genres and preferences. Whether you're a fan of action-packed shooters, immersive role-playing adventures, thrilling sports simulations, or mind-bending puzzle games, we have something for everyone.</p>
              </div>
              <div className="col-lg-4 d-flex justify-content-center align-items-center">
                <div className='category-img-container'>
            <img src={CategoryBg} alt="" className=''/>
            </div>
        </div>
        <div class="col-lg-8 py-5 text-light">
          <div class="row">
        
            <div class="col-lg-12">
              
            </div>
            <div class="col-lg-6 col-sm-6">
            <a href="https://funexch.com/" target='_blank' className='text-light text-decoration-none'>
              <div class="service-item">
                <div className='d-flex align-items-center gap-3'>
                <div class="icon">
                  <img src={Horse} alt="discover SEO" class="templatemo-feature" />
                </div>
                <h4>Exchange</h4>
                </div>
                <ul>
                  <li>Cricket</li>
                  <li>Football</li>
                  <li>Tennis</li>
                  <li>Horse Racing</li>
                  <li>Hound Racing</li>
                </ul>
               
              </div></a>
            </div>
            <div class="col-lg-6 col-sm-6">
            <a href="https://funexch.com/" target='_blank' className='text-light text-decoration-none'>
              <div class="service-item">
            
                <div className='d-flex align-items-center gap-3'>
                <div class="icon">
                  <img src={Cards} alt="discover SEO" class="templatemo-feature" />
                </div>
                <h4>Live Cards</h4>
                </div>
                <ul>
                  <li>Teen Patti</li>
                  <li>Poker</li>
                  <li>1 Cards games</li>
                  <li>Classic Indian</li>
                  <li>International</li>
                </ul>
               
              
              </div></a>
            </div>
            <div class="col-lg-6 col-sm-6">
            <a href="https://funexch.com/" target='_blank' className='text-light text-decoration-none'>
              <div class="service-item">
              <div className='d-flex align-items-center gap-3'>
                <div class="icon">
                  <img src={Casino} alt="discover SEO" class="templatemo-feature" />
                </div>
                <h4>Live Casino</h4>
                </div>
                <ul>
                  <li>Baccarat</li>
                  <li>Poker</li>
                  <li>Blackjack</li>
                  <li>Poker</li>
                  <a href="https://funexch.com/" target='_blank' >many more...</a>
                </ul>
              </div></a>
            </div>
            <div class="col-lg-6 col-sm-6">
              <a href="https://funexch.com/" target='_blank' className='text-light text-decoration-none'>
              <div class="service-item">
              <div className='d-flex align-items-center gap-3'>
                <div class="icon">
                  <img src={Slots} alt="discover SEO" class="templatemo-feature" />
                </div>
                <h4>Slots</h4>
                </div>
                <ul>
                  <li>Bingo</li>
                  <li>Fishing Games</li>
                  <li>Virtual Sports</li>
                  <li>Hunting</li>
                  <a href="https://funexch.com/" target='_blank' >many more...</a>
                </ul>
              </div></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default Categories