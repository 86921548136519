import React from 'react'
import { step1, step2, step3 } from '../../assets/images'

function Steps() {
  return ( 
  <div className="container">
    <div className='steps-container'>
       <h4 className='sub-heading p-2'>
Steps For Installing Fun Exch Mobile App:
       </h4>
        <div className="row">
            <div className="col-sm-4">
                <div className="step-container">
                <img src={step1} alt="step1" />
                <p>Go to your device's settings and locate "Security" &gt; "Unknown sources." Enable the installation of third-party software.</p>
                </div>
            </div>
            <div className="col-sm-4">
            <div className="step-container">
            <img src={step2} alt="step2" />
            <p>Visit the Fun exch website using your mobile browser, scroll down, and tap the "Download App" button to obtain the .apk file.</p>
            </div>
            </div>
            <div className="col-sm-4">
            <div className="step-container">
            <img src={step3} alt="step3" />
            <p>Once the download is complete, open the file and proceed with the installation.</p>
            </div>
            </div>
        </div>
        </div>
        
    
</div>
  )
}

export default Steps