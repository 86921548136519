import React from 'react'
import { Advantages, Banner, Categories, Footer, Header, Main, MobileApp } from '../../Components'

function Home() {
  return (
    <div>
        <Header/>
        <Banner/>
        <Main/>
        <Categories/>
        <Advantages/>
        <MobileApp/>
        <Footer/>
    </div>
  )
}

export default Home