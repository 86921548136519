import React from 'react'
import { facebook, instagram, logo, phone, playstore, telegram, twitter, whatsapp } from '../../assets/images'

function Footer() {
    return (
        <div className='footer'>
            <div className="container">
                <div className="row">
                    <div className="col-sm-6 p-3 ">
                        <a href="https://funexch.com/" target='_blank'>
                            <img src={logo} alt="Logo" className='footer-logo' />
                        </a>
                        
                        <p>FUNEXCHANGE is a limited liability company incorporated under the laws of Curacao Players are requested not to contact any untrusted sources for FUNEXCHANGE accounts as this is an online site and they can only register independently without any agents. Only deposit through the account details generated by the system or provided by our official support team.</p>
                    </div>
                    <div className="col-sm-6 px-4 d-flex flex-column justify-content-center align-items-center">
                        <div className="d-flex justify-content-evenly pt-3">
                            <div className="col-6">
                                <h6 className='text-light'>Download Mobile Application</h6></div>
                            <div className="col-6">
                            <a href="https://cdn.mac1j.com/apks/FUNEXCH1.0.3.apk" target='_blank'>
                                <img src={playstore} alt="playstore" className='footer-icons' />
                                </a>
                            </div>
                        </div>

                        <div className="row mt-3">
                            <div className='col-6 text-start'>
                                <h6 className=' text-light'>24/7 Helpline:</h6>
                            </div>
                            <div className="col-6">
                                <div className='d-flex  justify-content-evenly'>
                                    <img src={whatsapp} alt="playstore" className='' />
                                    <a href="tel:+919126388888 " className='text-light text-decoration-none'> +919126388888 </a>
                                </div>
                            </div>




                        </div>
                    </div>
                </div>
                <div className="d-flex flex-column align-items-center pt-4">
                    <h6 className='text-light'>Social</h6>
                    <div className="social-icons pb-4 pt-2">

                        <div className="d-flex gap-3">
                            <a href="https://www.instagram.com/funexchofficial/" target='_blank'>
                                <img src={instagram} alt="instagram" />
                            </a>
                            <a href="https://telegram.me/funexchofficial" target='_blank'>
                                <img src={telegram} alt="telegram" />
                            </a>
                            <a href="https://www.facebook.com/funexchofficial" target='_blank'>
                                <img src={facebook} alt="facebook" />
                            </a>
                            <a href="https://twitter.com/funexchofficial" target='_blank'>
                                <img src={twitter} alt="twitter" />
                            </a>




                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Footer
