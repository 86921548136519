import logo from "./logo.svg";
import game1 from "./games/2-cards.jpg";
import game2 from "./games/20-20-Poker.jpg";
// import game3 from "./games/2020poker.jpg";
import game4 from "./games/EVOLUTION-bacbo-BacBo00000000001.jpg";
import game5 from "./games/EVOLUTION-blackjack-LightningSbj0001.jpg";
import game6 from "./games/EVOLUTION-dealnodeal-dealnodeal000001.jpg";
import game7 from "./games/EVOLUTION-megaball-MegaBall00000001.jpg";
import game8 from "./games/EVOLUTION-moneywheel-nvrpo3bv6teqkuco.jpg";
import game9 from "./games/EVOLUTION-roulette-otctxzr5fjyggijz.jpg";
import game10 from "./games/EVOPLAY-5687.jpg";
import game11 from "./games/32-Cards (1).jpg";
import Cards from "./cards.svg";
import Casino from "./casino.svg";
import Horse from "./horse.svg";
import Slots from "./slots.svg";
import CategoryBg from "./slotGamesDesk.4e6e2683357947f36039.png";
import star from "./star.svg";
import person1 from "./person1.jpg";
import person2 from "./person2.jpg";
import person3 from "./person3.jpg";
import trophy from "./trophy.svg";
import person from "./person.svg";
import meta_icon from "./meta_icon.png";
// Screenshots
import ss1 from "./ss/ss2.jpg";
import ss2 from "./ss/ss3.jpg";
import ss3 from "./ss/ss4.jpg";
import ss4 from "./ss/ss5.jpg";
import ss5 from "./ss/ss6.jpg";
import ss6 from "./ss/ss7.jpg";
import ss7 from "./ss/ss8.jpg";
import ss8 from "./ss/ss9.jpg";
import ss9 from "./ss/ss10.jpg";
import ss10 from "./ss/ss11.jpg";
import ss11 from "./ss/ss12.jpg";

// Steps
import step1 from "./Step1.png";
import step2 from "./Step2.png";
import step3 from "./Step3.png";

// Footer
import playstore from "./playstore.svg";
import phone from "./phone.svg";
import whatsapp from "./whatsapp.png";
// Social
import instagram from "./instagram.png";
import telegram from "./telegram.png";
import facebook from "./facebook.png";
import twitter from "./twitter.png";
// Banner
import banner1 from "./banner/Banner.jpg";
import mobileBanner1 from "./banner/mobile-banner.jpg";

const appSS=[ss1,ss2,ss3,ss4,ss5,ss6,ss7,ss8,ss9,ss10,ss11]
export {
    logo,
    // game1,
    game2,
    // game3,
    game4,
    game5,
    game6,
    game7,
    game8,
    game9,
    game10,
    game11,
    Slots,
    Cards,
    Casino,
    Horse,
    CategoryBg,
    person1,
    person2,
    person3,
    meta_icon,
    star,
    trophy,
    person,
    appSS,
    step1,
    step2,
    step3,
    playstore,
    phone,
    whatsapp,
    instagram,
    facebook,
    telegram,
    twitter,
    banner1,
    mobileBanner1
}