import React from 'react'
import { Navbar,Container,Nav,NavDropdown,Button } from "react-bootstrap";
import { logo } from '../../assets/images';

function Header() {
  return (
    <Navbar expand="lg" style={{backgroundColor:"#000000"}} >
      <Container fluid className='d-flex justify-content-between'>
        <Navbar.Brand href="https://funexch.com/" target='_blank'><img src={logo} alt="Logo" className='navbar-logo'/></Navbar.Brand>
       
        
         
          <div className="d-flex gap-2">        
            <a className="nav-button nav-signup" href="https://funexch.com/" target='_blank'>Signup</a>
            <a className="nav-button nav-download" href="https://cdn.mac1j.com/apks/FUNEXCH1.0.3.apk" target='_blank'>Download App</a>
          </div>
       
      </Container>
    </Navbar>
  )
}

export default Header
