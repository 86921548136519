import React, { useEffect } from 'react'
import ScreenShotSlider from './ScreenShotSlider'
import Steps from './Steps'



function MobileApp() {
   
      
  return (
   <div className='w-100 mobile-app'>
    <div className="container">
     <div class="section-heading">
                <h2 className='green-heading'>Experience Unlimited Fun with FunExch Mobile App!
                </h2>
                <div class="line-dec"></div>
                <p>Introducing FunExch, the ultimate mobile app designed to bring unlimited fun and excitement to your fingertips. Download the app today and embark on a thrilling journey of entertainment, laughter, and joy like never before!.</p>
              </div>
              <div className='pb-5 pt-3'>
                <a href="https://funexch.com/" target='_blank' className='download-button'>Download Now</a>
                
              </div>
   <Steps/>
   <ScreenShotSlider/>
</div>
   </div>
  )
}

export default MobileApp