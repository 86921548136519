import React, { useEffect, useState } from 'react'
import { banner1, mobileBanner1 } from '../../assets/images'

function Banner() {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
          setScreenWidth(window.innerWidth);
        };
    
        // Attach the event listener
        window.addEventListener('resize', handleResize);
    
        // Clean up the event listener on component unmount
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, [window]);
    
  return (
    <div>{
        screenWidth >= 1024 
        ? <img src={banner1} alt="" className='img-fluid'/>
        :<img src={mobileBanner1} alt="" className='img-fluid'/>
    }</div>
  )
}

export default Banner
