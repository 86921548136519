import React from 'react'
import { meta_icon, person, person1, person2, person3, star, trophy } from '../../assets/images'


function Advantages() {
  return (
    <section id="app_advantages" className="sec_padding">
		<div className="container">
            <div className="row">
                <div class="section-heading">
                <h2 className='white-heading'>FunExch Advantages: Your Winning Edge
                </h2>
                <div class="line-dec"></div>
                <p>Unlock a world of advantages at FunExch. Enjoy enhanced security measures, lightning-fast payouts, and a wide range of exclusive bonuses, giving you the winning edge in online gaming.</p>
              </div>
                <div className="col-xl-4 col-lg-4 col-sm-12 col-md-6">
                    <div className="box-item">
                    <div className="d-flex gap-4">
                    <h4 className='achievement-box-heading d-flex gap-4 sub-heading'> <img src={star} alt="star" /> Highly Rated</h4>
                    </div>
                <div className="members_div aic">
                    <div className='d-flex pb-4'>
								<ul className="df aic all_user_ul">
									<li><img src={person1} alt="" /></li>
									<li><img src={person2} alt="" /></li>
									<li><img src={person3} alt="" /></li>
								</ul>
								<h5>The <span>Fun Exch</span> Members</h5>
                                </div>
                                <div className="rb_content">
							Fun Exch an ever-growing membership base, now totaling over +836.7K members and counting...
							</div>
<div className='w-100 d-flex justify-content-center'>
							<a href="https://funexch.com/" target='_blank' className="web_link">Join us now!</a>
                            </div>
							</div>
                    </div>
                   
                </div>
                <div className="col-xl-4 col-lg-4 col-sm-12 col-md-6">
                <div className="box-item">
                
              
                    <h4 className="achievement-box-heading d-flex gap-4 sub-heading"><img src={person} alt="" />Top user experience</h4>
                    
                    <p className='rb_content'>
                    Welcome to FunExch! Immerse yourself in seamless navigation, intuitive design, and a vast array of thrilling games for an extraordinary gaming experience. Join us now and unleash the excitement!
                        </p>
                        <div className='w-100 d-flex justify-content-center'>
                        <a href="https://funexch.com/" target='_blank' className="web_link">Join us now!</a></div>
                    </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-sm-12 col-md-12">
                    <div className="box-item">
                    <div className='achievements_box'>
                    <h4 className='achievement-box-heading d-flex gap-4 sub-heading'> <img src={trophy} alt="star" />Achievements</h4>
                   
						<ul>
							<li>High odds that update every second</li>
							<li>Exciting tournaments for betting.</li>
							<li>Extensive range of esports available.</li>
							<li>Top-notch security measures.</li>
							<li>24*7 customer support.</li>
                            <li>Easy and quick betting with just a few clicks.</li>
						</ul>
                        <div className='w-100 d-flex justify-content-center'>
						<a href="https://funexch.com/" className="web_btn">Download app</a>
                        </div>
					</div>
                    </div>
                    </div>
            </div>
        </div>
	</section>
  )
}

export default Advantages