import React from 'react'
import MainSlider from './MainSlider'

function Main() {
   
  return (
    <section class="hero-section">
                <div class="container">
                  
                            <div class=" mb-5 pb-2 d-flex flex-column justify-content-center">
                                <h1 class="main-heading"> Download Our New App & Get 6% real cash on every Deposit. No Wagering, No Rollover, No conditions.
                                    <br/>
                                    FunExch - India's Biggest Online Casino & Sportsbook
                                    <br/>
                                    Download Now for Android </h1>

<div className='d-flex justify-content-center'>
<a href="https://cdn.mac1j.com/apks/FUNEXCH1.0.3.apk" target='_blank' class="btn smoothscroll mt-3">Download App</a>
</div>
                                
                            </div>

                </div>
            </section>
  )
}

export default Main
